<template>
  <div class="living clearfix">
    <div class="liveLeft fl">
      <div style="height: 100%">
        <div v-if="PJSource == 6">
          <AliyunVideo
            v-if="livingList.length === 0 || isAliyun"
            :AliyunId="currentPlayId"
          ></AliyunVideo>
        </div>
        <el-image
          v-if="livingList.length > 0 && !isAliyun"
          class="img"
          :class="{ cursor: nowLive && nowLive.watch_url }"
          :src="nowLive.live_img"
          @click="handleClick(nowLive)"
        ></el-image>
        <div v-else class="noliveshoimg">
          <al-image
            class="img"
            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/airExpo/banner/live_banner.png"
          >
          </al-image>
          <!-- 时间梳理 -->
          <div class="text">直播倒计时{{liveCountDown}}天</div>
          <!-- <div class="texttime" v-if="PJSource == 21">{{$t('selectBooth22')}}</div> -->
        </div>
      </div>
      <div class="liveStatus clearfix">
        <div class="left fl">
          <div v-if="livingList.length > 0">
            <div class="clearfix nostart" v-show="nowLive.isTimer == 1">
              <div class="line fl"></div>
              <div class="fl">{{ $t("haveNotStarte") }}</div>
            </div>
            <div class="clearfix end" v-show="nowLive.isTimer == 3">
              <div class="line fl"></div>
              <div class="fl">{{ $t("meetingReturnLive") }}</div>
            </div>
            <div class="clearfix underway" v-show="nowLive.isTimer == 2">
              <div class="line fl"></div>
              <div class="fl">{{ $t("meetingBegining") }}</div>
            </div>
          </div>
        </div>
        <div
          class="right fl textOverflow"
          v-if="nowLive.issue_zh || nowLive.issue_en"
        >
          {{
            nowLive.issue_en
              | priorFormat(nowLive.issue_zh, LOCALE)
              | lineFormat
          }}
        </div>
      </div>
    </div>
    <div class="liveRight fr" v-loading="liveLoading">
      <div>
        <div class="dateTitle" v-if="isDateType == 'date'">
          <div v-if="livingList.length > 0">
            {{ livingList.length > 0 && livingList[0].date }}
          </div>
          <div v-else>{{ dateTitle }}</div>
        </div>
        <div class="dateTitle clearfix" v-if="isDateType == 'tabs'">
          <el-tabs :value="activeName" @tab-click="checkTabs">
            <el-tab-pane
              v-for="(it, index) in livingList"
              :key="index"
              :label="it.date"
              :name="it.date"
            ></el-tab-pane>
          </el-tabs>
          <!-- <div v-for="(it, index) in livingList" class="fl dateTabs" :key="index">
            <span>{{ it.date }}</span>
          </div> -->
        </div>
        <div class="todayData">
          <div v-if="livingList.length > 0">
            <div
              class="todayItem"
              v-for="(it, index) in livingList[tabIndex].dataList"
              :key="index"
            >
              <div class="clearfix">
                <div class="fl">
                  {{ it.start_time | secondFormat("T") }} -
                  {{ it.end_time | secondFormat("T") }}
                </div>
                <div class="fr">
                  <span v-show="it.live_type == 1">{{ $t("MainVenue") }}</span>
                  <span v-show="it.live_type == 2">{{ $t("pavillion") }}</span>
                </div>
              </div>
              <div class="clearfix">
                <div v-if="it.agenda_type == 1" class="livedet">
                  <div class="fl type">{{ $t("meetingZhibo") }}</div>
                  <div
                    class="fl text textOverflow issueHover"
                    @click="issueHoverDate(it)"
                    :title="it.issue_en | priorFormat(it.issue_zh, LOCALE)"
                  >
                    {{ it.issue_en | priorFormat(it.issue_zh, LOCALE) }}
                  </div>
                </div>
                <div v-if="it.agenda_type == 2" class="meetdet">
                  <div class="txtOverflow fl" style="line-height: 20px">
                    {{ $t("roundLive") }}：
                  </div>
                  <div
                    class="textOverflow text1 fl issueHover"
                    @click="issueHoverDate(it)"
                    :title="it.issue_en | priorFormat(it.issue_zh, LOCALE)"
                  >
                    {{ it.issue_en | priorFormat(it.issue_zh, LOCALE) }}
                  </div>
                </div>
                <div class="fr status clearfix">
                  <div class="fl div">
                    <!-- <div
                      v-show="it.start_time > it.now_time"
                      class="nostart cursor"
                    >
                      <span
                        v-show="!it.is_subscribe"
                        @click="remind(it, index)"
                      >
                        {{ $t("meetingAppointment") }}</span
                      >
                    </div>
                    (it.start_time < it.now_time &&
                          it.now_time < it.end_time) ||
                        (it.now_time < it.start_time && it.is_subscribe)-->
                    <div
                      v-show="it.isTimer != 3"
                      class="underway"
                      :class="{
                        cursor: it.watch_url,
                      }"
                      @click="handleClick(it)"
                    >
                      {{ $t("meetingEnter") }}
                    </div>
                    <div
                      v-show="it.isTimer == 3"
                      class="end"
                      :class="{
                        cursor: it.watch_url,
                      }"
                      @click="handleClick(it)"
                    >
                      {{ $t("meetingReturnLive") }}
                    </div>
                  </div>
                  <div class="fr" v-if="it.isTimer != 3">
                    <el-image
                      :src="idcodeImg"
                      style="width: 20px; margin-left: 5px"
                      class="cursor"
                      @click="isIdCodeDialog(it.watch_url)"
                    ></el-image>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <noDataImg v-if="livingList.length == 0"></noDataImg>
        </div>
      </div>
      <div
        v-if="isDateType == 'date'"
        class="cursor dateIcon"
        @click="dialogVisible = true"
        v-show="!dataImg"
      >
        <!-- <i class="el-icon-date size mainColor"></i> -->
        <el-image
          class="img"
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/images/timeData.png"
        ></el-image>
      </div>
    </div>
    <!-- 日历选择 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="325px"
      class="selectTime"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
    >
      <datePicker
        @dateSelect="getdate"
        :start="startTime"
        :end="endTime"
        :default="defaultTime"
      ></datePicker>
    </el-dialog>
    <idCode ref="idCode" :isCustom="true" :customUrl="customUrl"></idCode>
  </div>
</template>
<script>
/**
 * 传给父组件的方法：
 * 1.dateChange(item)点击日历切换日期发射的方法（此方法主要功能就是刷新数据，把当前页面数据改成所选日期的数据）
 * 2.toLivePath(item)点击进入直播的方法
 *
 *
 * 插槽：slot：status（右边按钮）
 */
import datePicker from "~/baseComponents/datePicker";
import idCode from "~/baseComponents/idCode";
import { secondFormat, lineFormat } from "~/basePlugins/filters";
import AliyunVideo from "@/baseComponents/AliyunVideo";
export default {
  components: {
    datePicker,
    idCode,
    AliyunVideo,
  },
  props: {
    livingList: {
      //数组要经过formatDate处理过的
      type: Array,
      default: function () {
        return [];
      },
    },
    isDateType: {
      type: String,
      default: "date",
    },
  },
  data() {
    return {
      meeting_status: "",
      activeName: "",
      idcodeImg: require("~scr/assets/img/idcode1.png"),
      defaultLiveImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/positionBanner.png",
      nowLive: {},
      dialogVisible: false,
      idcodeVisible: false,
      dateTitle: "",
      liveLoading: false,
      tabIndex: 0,
      startTime: 1606752000,
      endTime: 1609430399,
      defaultTime: 1606752000,
      isAliyun: false,
      customUrl: null,
      dataImg: false,
      currentPlayId: "23e9ffa6d5174e6188a207868c77734b",
      // currentPlayId:"9cdf4ff44eae4b929f845737d508c6f3"
    };
  },
  computed: {
    liveCountDown() {
      let m1 = this.$moment("2022-08-11"),
        m2 = this.$moment();
      let res = m1.diff(m2, "day");
      return res;
    },
  },
  watch: {
    livingList() {
      if (this.isDateType == "tabs" && this.livingList.length > 0) {
        let obj, objIndex;
        let today = moment(new Date()).format("YYYY-MM-DD");
        this.livingList.forEach((it, index) => {
          if (it.date == today) {
            obj = it;
            objIndex = index;
          }
        });
        if (obj && obj.date) {
          this.activeName = obj.date;
          this.tabIndex = objIndex;
        } else {
          this.activeName = this.livingList[0].date;
        }
      }
      this.liveWatchImg();
    },
  },
  mounted() {
    this.startTime = this.MEETING_INFO.start_time;
    this.endTime = this.MEETING_INFO.end_time;
    let meeting_status;
    let info = this.MEETING_INFO;
    let nowTime = moment(new Date()).unix();
    if (nowTime < info.start_time) {
      // 未开始
      this.radio = 1;
      meeting_status = 1;
    } else if (info.start_time < nowTime && info.end_time > nowTime) {
      // 进行中
      this.radio = 2;
      meeting_status = 2;
    } else if (info.end_time < nowTime) {
      // 结束了
      this.radio = 3;
      meeting_status = 3;
    } else {
    }
    this.meeting_status = meeting_status;
    if (this.meeting_status == 2) {
      this.dateTitle = moment(new Date()).format("YYYY-MM-DD");
    } else {
      this.dateTitle = moment(this.startTime * 1000).format("YYYY-MM-DD");
    }
    let time = this.endTime - this.startTime;
    if (time < 86400) {
      this.dataImg = true;
    }
  },
  methods: {
    // 左侧封面图
    liveWatchImg(obj) {
      if (this.livingList.length == 0) {
        this.nowLive = {};
        return false;
      }
      this.isAliyun = false;
      let arr;
      if (this.isDateType == "tabs") {
        arr = this.livingList[this.tabIndex].dataList;
      } else {
        arr = this.livingList[0].dataList;
      }
      let todayStart = moment().startOf("day");
      let todayEnd = moment().endOf("day");
      let nowTime = moment(new Date()).unix();
      let arrFil = [];
      if (obj) {
        this.nowLive = obj;
      } else {
        if (this.livingList.length > 0 && arr.length > 0) {
          let objNow;
          arr.forEach((it) => {
            if (
              it.start_time <= nowTime &&
              nowTime < it.end_time &&
              it.agenda_type == 1
            ) {
              objNow = it;
            } else if (
              it.start_time <= nowTime &&
              nowTime < it.end_time &&
              it.agenda_type == 2
            ) {
              objNow = it;
            }
          });
          let objNext = arr.find((it) => {
            return it.start_time > nowTime;
          });
          if (objNow) {
            this.nowLive = objNow;
          } else if (objNext) {
            this.nowLive = objNext;
          } else if (!objNext) {
            this.nowLive = arr[arr.length - 1];
          } else {
            this.isAliyun = true;
            this.nowLive = {};
          }
        } else {
          this.nowLive = {};
        }
      }
    },
    handleClick(item) {
      // if (item.isTimer == 1 && !item.is_subscribe) {
      //   this.$message.warning("请先预约");
      // } else {
      // }
      this.$emit("toLivePath", item);
    },
    issueHoverDate(it) {
      // this.nowLive = it;
      this.liveWatchImg(it);
    },
    // 二维码
    isIdCodeDialog(url) {
      this.customUrl = url;
      this.$refs.idCode.isIdCodeDialog();
    },
    loadingShow(val) {
      this.liveLoading = val;
    },
    // 切换日期
    getdate(result) {
      this.dialogVisible = false;
      let obj = {};
      obj.start_time = result.startTime;
      obj.end_time = result.endTime;
      this.$emit("dateChange", obj);
      this.liveWatchImg();
      this.dateTitle = moment(result.time * 1000).format("YYYY-MM-DD");
    },
    checkTabs({ index }) {
      this.tabIndex = index;
      // let dates = new Date(name);
      // let todayStart = moment(dates).startOf("day").unix();
      // let todayEnd = moment(dates).endOf("day").unix();
      // let obj = {};
      // obj.start_time = todayStart;
      // obj.end_time = todayEnd;
      // this.liveWatchImg();
    },
    // 预约
    async remind(item, index) {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      } else {
        if (item.is_subscribe == 1) {
          this.$message({
            message: "您已成功预约",
            type: "warning",
            offset: 100,
          });
          return false;
        }
        let params = {};
        params.agenda_type = item.live_type;
        params.user_id = this.USER_ID;
        params.meeting_id = this.MEETING_ID;
        params.remind_time = item.start_time;
        params.agenda_id = item.id;
        try {
          let res = await this.$store.dispatch(
            "baseStore/reservationMeetingBoothAgenda",
            params
          );
          if (res.success) {
            if (res.remind_id) {
              this.$message({
                message: "预约成功",
                type: "success",
                offset: 100,
              });
              this.livingList[0].dataList[index].is_subscribe = 1;
            } else {
              this.$message({
                message: "预约失败",
                type: "error",
                offset: 100,
              });
            }
          }
        } catch (error) {}
      }
    },
  },
};
</script>
<style lang="less" scoped>
.living {
  min-height: 300px;
  margin-bottom: 30px;
  .liveLeft {
    width: 976px;
    height: 563px;
    position: relative;
    background: #fff;
    .img {
      height: 563px;
      width: 100%;
    }
    .liveStatus {
      position: absolute;
      top: 20px;
      left: 20px;
      padding: 2px 10px;
      .left {
        border-radius: 3px;
        line-height: 24px;
        div {
          color: #fff;
        }
        .nostart {
          padding: 2px 10px;
          background: linear-gradient(90deg, #6fbcff 0%, #3283f6 100%);
        }
        .underway {
          padding: 2px 10px;
          background: linear-gradient(270deg, #fa2d64 0%, #f41434 100%);
        }
        .end {
          padding: 2px 10px;
          background: linear-gradient(270deg, #e6a52c 0%, #fad55e 100%);
        }
      }
      .right {
        line-height: 24px;
        padding: 2px 10px;
        background: rgba(0, 0, 0, 0.62);
        color: #fff;
        max-width: 800px;
      }
      .line {
        width: 6px;
        height: 6px;
        background: #fefefe;
        border-radius: 3px;
        margin: 10px 5px 0 0;
      }
    }
  }
  .liveRight {
    width: 300px;
    height: 563px;
    background: #fff;
    border-radius: 2px;
    padding: 16px 0;
    overflow: hidden;
    position: relative;
    .dateTitle {
      font-size: 14px;
      padding: 0 16px;
      /deep/.el-tabs__header {
        margin-bottom: 5px;
      }
    }
    .todayData {
      overflow: auto;
      height: 500px;
      padding: 0 16px;
    }
    .todayItem {
      height: 70px;
      border-bottom: 1px solid #e7e7e7;
      padding: 12px 0;
      .livedet,
      .meetdet {
        margin-top: 8px;
        .type {
          padding: 0 5px;
          height: 20px;
          background: #26b7ff;
          border-radius: 3px;
          line-height: 20px;
        }
        .text {
          max-width: 46%;
          line-height: 20px;
          margin: 0 8px;
        }
        .text1 {
          max-width: 45%;
          line-height: 20px;
          margin: 0 8px;
        }
      }
      .status {
        .div div {
          padding: 0 8px;
          line-height: 20px;
          border-radius: 3px;
        }
        .nostart {
          background: linear-gradient(90deg, #6fbcff 0%, #3283f6 100%);
          color: #fff;
        }
        .underway {
          background: linear-gradient(270deg, #fc6767 0%, #ec008c 100%);
          color: #fff;
        }
        .end {
          color: #fad55e;
          border: 1px solid #fad55e;
        }
      }
    }
  }
  .issueHover {
    &:hover {
      color: #26b7ff;
      cursor: pointer;
    }
  }
  .dateIcon {
    position: absolute;
    top: 15px;
    right: 20px;
    .img {
      width: 25px;
      height: 25px;
    }
  }
}
.dateTabs {
  margin-right: 16px;
}
.selectTime {
  /deep/.el-dialog__body {
    padding: 0;
    width: 320px;
  }
}

/deep/.el-picker-panel {
  margin: 0;
}
/deep/.el-dialog__headerbtn {
  top: 10px;
}
.noliveshoimg{
  position: relative;
  .text{
    padding-left: 10px;
    width: 510px;
    height: 90px;
    position: absolute;
    color: #fff;
    font-size: 32px;
    font-weight: 900;
    left: 233px;
    top: 219px;
    display: flex;
    justify-content:center;
    align-items: center;
    letter-spacing:26px;
  }
  .texttime{
    padding-left: 10px;
    width: 510px;
    height: 90px;
    position: absolute;
    color: #fff;
    font-size: 18px;
    font-weight: 900;
    left: 233px;
    top: 219px;
    display: flex;
    justify-content:center;
    align-items: center;
    letter-spacing:26px;
  }
}
</style>
